<template>
  <div
    class="small game-online"
    v-bind:class="[
      state === 'live' ? dataSport.textClass : 'text-white-50 state-small'
    ]"
  >
    {{ state }}
  </div>
</template>

<script>
export default {
  name: "MlbGameState",
  props: ["state", "dataSport"]
};
</script>

<style scoped>
.game-online {
  letter-spacing: 1px;
  font-size: 0.8rem;
}
.state-small {
  font-size: 0.6rem;
}
</style>
